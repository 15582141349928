import logo from './logo.svg';
import './App.css';
import portrait from './Portrait.png'
import linkedin from './linkedin.png'
import github from './Github.png'
import nuget from './nuget.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <table>
          <tr><td rowSpan={4}><img src={portrait} height={100} /></td></tr>
          <tr><td><b>Jeff Bramlett</b></td></tr>
          <tr><td><i>Empowering Innovation through Cloud Architecture and Advanced Software Solutions</i></td></tr>
          <tr><td>
            <div className='small-link'>
              <a href='https://www.linkedin.com/in/jeffreybramlett'><img src={linkedin}/> LinkedIn</a> |
              <a href='https://www.nuget.org/profiles/JeffBramlett'><img src={nuget}/>Nuget</a> |
              <a href='https://github.com/JeffBramlett'><img src={github}/>GitHub</a>
            </div>
          </td></tr>
        </table>
        <hr />
      </header>
      <body>
        <div className='content'>
          <p>Throughout my career, I've had the privilege of shaping the digital landscapes of diverse organizations. My expertise spans designing and implementing cloud-native applications, leading Agile transformations, and pioneering the application of artificial intelligence and machine learning to solve complex business challenges.</p>
          <p>From my role as a Principal Architect at Cambridge Associates, where I led the modernization of legacy systems to AWS, to initiating groundbreaking AI proof of concept applications, my career is a testament to my dedication to technological advancement and excellence. Each position I've held has been a stepping stone towards understanding the intricate dance of software architecture with business needs, all while mentoring the next generation of engineers and architects.</p>
          <h2>My Certifications</h2>
          <ul>
            <li>Amazon Web Services Solutions Architect</li>
            <li>Scrum alliance Cerfied Scrum Master</li>
            <li>Microsoft Certified Windows Communication Foundation Developer</li>
          </ul>
          <h2>Here's What I Offer</h2>
          <ul>
            <li>Cloud Architecture Consultancy: Leveraging AWS to efficiently architect, modernize, and scale applications.</li>
            <li>Agile and DevOps Transformation: Implementing Agile methodologies and DevOps practices to streamline development and operations.</li>
            <li>AI and Machine Learning Solutions: Developing innovative AI and machine learning applications for business intelligence, automation, and enhanced decision-making.</li>
            <li>Mentorship and Leadership: Sharing knowledge and guiding teams and individuals toward achieving their full potential in technology.</li>
          </ul>
          <h2>Engage with Me</h2>
          <p>Whether you're seeking to revolutionize your organization's technology stack, explore the potential of AI and machine learning, or simply looking for insights into the latest trends in cloud computing, you're in the right place. I invite you to explore my website, where you'll find detailed insights into my projects, thought leadership articles, and how we can collaborate to turn your vision into reality.</p>
        </div>
      </body>
      <footer className='App-footer'>
        <hr />
        <p>Copyright Jeff Bramlett (2024) all rights reserved</p>
      </footer>
    </div>
  );
}

export default App;
